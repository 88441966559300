.Apart-aminities{
    margin-top: 10vh;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    display: grid;
    grid-template-columns: auto auto auto;
    row-gap: 30%;
    column-gap: 10%;
    margin-bottom: 30vh;
}
.aminities{
display: flex;
    }

.data{
    position: relative;
    left: 2vw;
    top: 1.2vh;
    font-weight: 500;
}

#logoimg{
    width:35px;
    height: 40px;
}

  /*------------------------------------------------------media queries------------------------------------------------*/

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .Apart-aminities{
        margin-top: 10vh;
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
        display: grid;
        grid-template-columns: auto;
        row-gap: 5%;
        justify-content: center;
        margin-bottom: 40vh;
    }
  }