

.finp::placeholder{
color: black;
}
.navlink {
    display: inline-block;
    position: relative;
    color: white;
    font-family: 'Source Sans Pro', sans-serif;
  
  }
  
  .navlink::after {
    content: "";
    position: absolute;
    width: 10%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0px;
    left: 0px;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .navlink:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }