/* @font-face {
  font-family: Humanst521 BT;
  src: url(assets/fonts/Humanst521\ BT.ttf);
} */

@import url("https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Prata&family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
