@font-face {
    font-family: ElMessiri-Bold;
    src: url(../../assets/fonts/static/ElMessiri-Bold.ttf);
  }

  #heading{
    font-size: 2rem;
    font-family: ElMessiri-Bold;
}

#appbar{
    max-width: 40%;
    margin-right: 30%;
    margin-left: 30%;
    margin-top: 5vh;
   
  }

 

  .css-sg3r2e-MuiTabs-root Button.Mui-selected {
    color: #731712 !important;
    background-color: white !important;
}
.MuiTabs-indicator {
  display: none;
}



  /*------------------------------------------------------media queries------------------------------------------------*/

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    #appbar{
      max-width: 90%;
      margin-right: 5%;
      margin-left: 5%;
      margin-top: 5vh;   
    }
  }