.room-images{
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
    gap: 2%;
    display: grid;
    grid-template-columns: auto auto auto;
    margin-bottom: 10% ;
}

/*------------ 4k devices (laptops/desktops, 2000px and up)----------------------- */


@media  (min-width: 2000px) and (max-width: 3000px) {
  
    .room-images{
        width: 60%;
        margin-left: 20%;
        row-gap: 20px;
        margin-right: 20%;
    }



}