.first1-div {
  position: relative;
  margin-top: 10vh;
  width: 100%;
}

.first1-img {
  width: 100%;
  height: auto;
  left: 0;
}

.first2-div {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, 0);
}

@media (max-width: 768px) {
  .first1-div {
    position: relative;
    margin-top: 3vh;
    width: 100%;
  }
  
}

/* -------- second ---------*/

.elMessiri{
  font-family:'El Messiri', sans-serif;
  font-weight: bold;
  font-size: xx-large;
  max-width: 80%;
  word-wrap: break-word;
}

.reg{
  font-size: 1rem;
  font-family: 'Source Sans Pro', sans-serif;
  max-width: 90%;
  word-wrap: break-word;
}


/*----Third -------*/
.third-sec{
  background-color: white;
  background: linear-gradient(180deg, white 50%,black 50%);
  margin-top: 7rem;
  padding-bottom: 2rem;
}

.third-text-div{
   width:"3%";
   text-align: center;
   margin-left: 38%;
}

.third-text{

  color: #731712 ;
  font-family: 'Source Sans Pro', sans-serif;
  width: 50%;
}

.third-text1{
  font-size: 1.6rem;
  font-family:'El Messiri', sans-serif;
  font-weight: bold;
  width: 50%;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.carousel__list8 {
  display: flex;
  list-style: none;
  padding: 0;
  padding: 1rem 0 0;
  overflow: hidden;
  position: relative;
  width: 98vw;
  margin: 0 auto;
  max-width: 100rem;
}

.carousel__item8 {
  flex: 0 0 auto;
  width: 19%;
  padding: 0;
  margin-right: 1rem;

 
}

.third-button-div{
  margin-left: 44%;
   margin-top: 5%;
  display:flex
}

@media (max-width: 700px) {
  
.third-text-div{
  width:"3%";
  text-align: center;
  margin-left: 20%;
  margin-right: 20%;
}

.third-text{

 color: #731712;
 font-family: 'Source Sans Pro', sans-serif;
 width: 90%;
}

.third-text1{
 font-size: 1.3rem;
 font-family:'El Messiri', sans-serif;
 font-weight: bold;
 width: 90%;
 white-space: wrap;
 overflow: hidden;
 text-overflow: ellipsis;

}

  .carousel__list8 {
    display: flex;
    list-style: none;
    padding: 0;
    padding: 1rem 0 0;
    overflow: hidden;
    position: relative;
    width: 100vw;
    margin: 0 auto;
    max-width: 100rem;
  }

  .carousel__item8 {
    flex: 0 0 auto;
    width: 33.33%;
    padding: 0;
  }

  .third-button-div{
    margin-left: 30%;
    margin-right: 20%;
     margin-top: 8%;
    display:flex
  }
}

/*----- fourth -----*/
.fourth-sec{
  display: flex;
  flex-basis: 100%;
  gap:3rem;
  background-color: black;
  align-items: center;
  padding: 3rem;
}

.fourth-text-div{
  flex-basis: 100%;
}

.fourth-text{
  color: white;
  max-width: 80%;
  margin-bottom: 1rem;
}

.fourth-text1{
  color: white;
  font-size: 1.78rem;
  font-family:'El Messiri', sans-serif;
  font-weight: bold;
  max-width: 77%;
  word-wrap: break-word;
}


.fourth-ol{
  margin-top: 1rem;
  color: white;

}

.fourth-li{
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: center;
}

.fourth-li > p {
  font-family: 'Source Sans Pro', sans-serif;
}


.google-map-code{
  flex-basis: 100%;
  justify-self: center;
}

.fourth-map{
  filter: grayscale(90%) invert(90%);
}


@media (max-width: 900px) {
  .fourth-sec{
    display: flex;
    flex-direction: column;
    gap:3rem;
    background-color: black;
    padding: 3rem 1rem; 
  }

  .fourth-text{
    color:  white;
    max-width: 100%;
    
  }
  
  .fourth-text1{
    color: white;
    font-size: 1.78rem;
    font-family:'El Messiri', sans-serif;
    font-weight: bold;
    max-width: 100%;
    word-wrap: break-word;
  }

  .google-map-code{
    flex-basis: 100%;
    width: 100%;
  }
  
}


/*-------fifth----------*/
.fifth-sec{
  margin-top: 7rem;
}

.fifth-text-div{
  width:"3%";
  text-align: center;
  margin-left: 37%;
}

.fifth-text{
 color: #731712;
 font-family: 'Source Sans Pro', sans-serif;
 width: 50%;
}

.fifth-text1{
 font-size: 1.72rem;
 font-family:'El Messiri', sans-serif;
 font-weight: bold;
 width: 50%;
 white-space: wrap;
 overflow: hidden;
 text-overflow: ellipsis;

}

.fifth-line-div{
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fifth-line-div > img{
  
  max-width: 80%;
  height: auto;
}

.fifth-news-div{
  display: flex;
  margin: 0 3rem;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
}

.fifth-news-card-div{
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.fifth-news-img{
  max-width: 100%;
  height: auto;
}

.fifth-news-date{
  font-family: 'Source Sans Pro', sans-serif;
  color: #731712;
  font-weight: 600;
}

.fifth-news-date2{
  color: #2F2F2F;
  font-family: 'Source Sans Pro', sans-serif;
}

.fifth-news-text1{
  font-family:'El Messiri', sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
}

.fifth-news-text2{
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}

.fifth-news-a{
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 0.75rem;
  color:#731712;
}

.carousel__list5 {
  display: flex;
  list-style: none;
  padding: 0;
  padding: 1rem 0 0;
  overflow: hidden;
  position: relative;
  width: 98vw;
  margin: 0 auto;
  max-width: 100rem;
}

.carousel__item5 {
  flex: 0 0 auto;
  width: 24%;
  padding: 0;
  margin-right: 1rem;
}



@media (max-width: 600px) {
  .fifth-text-div{
    margin-left: 0%;
    text-align: center;
  }

  .fifth-text{
    color: #731712;
    font-family: 'Source Sans Pro', sans-serif;
    margin: 0 auto;
   }
   
   .fifth-text1{
    font-size: 1rem;
    font-family:'El Messiri', sans-serif;
    font-weight: bold;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 auto;

   }

   .fifth-news-div{
    display: flex;
    margin: 0 2rem;
    gap: 1.5rem;
    flex-direction: nowrap;
    justify-content: center;
    
  }
  
  .fifth-news-card-div{
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  

  .fifth-news-text1{
    font-family:'El Messiri', sans-serif;
    font-size: 1rem;
    font-weight: 700;
  }

  .fifth-news-date{
    font-family: 'Source Sans Pro', sans-serif;
    color: #731712;
    font-size: 0.8rem;
    font-weight: 600;
  }
  
  .fifth-news-date2{
    color: #2F2F2F;
    font-size: 0.7rem;
    font-family: 'Source Sans Pro', sans-serif;
  }
  
  .fifth-news-text2{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
  }
  
  .fifth-news-a{
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 0.75rem;
    color:#731712;
  }

  .carousel__list5 {
    display: flex;
    list-style: none;
    padding: 0;
    padding: 1rem 0 0;
    overflow: hidden;
    position: relative;
    width: 100vw;
    margin: 0 auto;
    max-width: 100rem;
  }

  .carousel__item5 {
    flex: 0 0 auto;
    width: 50%;
    padding: 0;
  }
}


/* -------------- six --------*/

.six-sec{
  display: flex;
  margin: 7rem 3rem;
  gap: 1rem;
  align-items: center;
}

.carousel__list6 {
  display: flex;
  flex-basis: 100%;
  list-style: none;
  padding: 0;
  padding: 1rem 0 0;
  overflow: hidden;
  position: relative;
  width: 100vw;
  margin: 0 auto;
  max-width: 38rem;
}

.carousel__item6{
  flex: 0 0 auto;
  width: 100%;
  padding: 0;
}

.six-text-div{
  flex-basis: 100%;
}

.six-text1{
  color : #731712;
  font-family: 'Source Sans Pro', sans-serif;
}

.six-text2{
  font-family:'El Messiri', sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  width: 70%;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.7rem;
}

.six-text3{
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
}

.six-icon{
  color: white;
  font-size: 1.7rem;
}

.six-btn-div{
  display:flex;
  justify-self: end;
  background-color:#731712;
  max-width: fit-content;
  position:absolute ;
  right: 0%;
  bottom: 0%;
}

@media (max-width: 980px){
  .six-sec{
    display: flex;
    flex-direction: column;
    margin: 7rem 2rem;
    gap: 2rem;
  }

  .carousel__list6 {
    display: flex;
    flex-basis: 100%;
    list-style: none;
    padding: 0;
    padding: 1rem 0 0;
    overflow: hidden;
    position: relative;
    width: 90vw;
    margin: 0 auto;
    max-width: 33rem;
  }
  
  .carousel__item6{
    flex: 0 0 auto;
    width: 100%;
    padding: 0;
  }
  
  .six-text2{
    font-family:'El Messiri', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    width: 90%;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0.7rem;
  }
  
  .six-icon{
    color: white;
    font-size: 1.3rem;
  }
  

}


/*----------seven-------------*/
.seven-div{
  margin: 7rem 3rem;
}

.seven-text-div{
  text-align: center;
  margin-left: 30%;
  max-width: 50%;
  margin-bottom: 2rem;
}

.seven-text1{
  color: #731712;
  font-family: 'Source Sans Pro', sans-serif;
  width: 80%;
  margin-bottom: 1rem;
}

.seven-text2{
  font-family:'El Messiri', sans-serif;
  font-size: 1.85rem;
  font-weight: 600;
  width: 80%;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.seven-main-div{
  display: flex;
  gap: 3rem;
  align-items: center;
}

.seven-sub-div1{
  flex-basis: 100%;
}

.seven-sub-div2{
  flex-basis: 30%;
}

.seven-main-div1{
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.seven-sub-text1{
  font-family: 'Source Sans Pro', sans-serif;
  color: #731712;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
}

.seven-sub-text2{
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.seven-img{
  max-width: 100%;
  height: auto;
}

@media (max-width:1100px){
  .seven-text-div{
    text-align: center;
    margin-left: 20%;
    max-width: 100%;
    margin-bottom: 2rem;
  }
  
  .seven-text1{
    color: var(--blue);
    font-family: 'Source Sans Pro', sans-serif;
   
    margin-bottom: 1rem;
  }
  
  .seven-text2{
    font-family:'El Messiri', sans-serif;
    font-size: 1.3rem;
    font-weight: 600;
  
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  

  .seven-main-div{
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;
  }
}