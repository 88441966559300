@font-face {
    font-family: ElMessiri-Bold;
    src: url(../../assets/fonts/static/ElMessiri-Bold.ttf);
  }


.form{

    position: relative;
    background-color: rgb(255, 255, 255); 
    height:auto;
    width: 50%;
    margin-top: -19%;
    left: 7%;
    box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.15);
    
}
#heading2{
   
    font-size: 2rem;
    font-family: ElMessiri-Bold;
    margin-top: -8%;
}

.form p{
    text-align: center;
    padding-top: 2%;
    font-weight: 600;
    line-height: 150%;
}

.form button{
    position: relative;
    right: 6%;
    margin-top: 2%;
    float: right;
    width: 28%;
    height: 7vh;
    background-color: #731712;
    border: none;
    border-radius: 4px;   
    cursor: pointer; 
    color: white;
}

.git a{
    text-decoration: none;
    cursor: pointer;
}
.forminput{
    outline: none;
    margin-top: 3%;
    margin-left: 12%;
    width: 100%;
    height: 7vh;
    border: 1px solid rgb(180, 180, 180);
    padding-left: 5%;
}
.form input::placeholder{
    /* padding-left: 8%; */
    color: black;
}

.comment::placeholder{
margin-top: 20%;
margin-left: 3%;
color: black;
}
.comment{
    border: 1px solid lightgray;
    height: 15vh;
    width: 89%;
    margin-top: 3%;
   margin-left: 5%;
   margin-right: 10%;
   padding-left: 2%;
    padding-top: 2.5%;
}
#options{
    margin-left: 5%;
    border: 1px solid lightgray;
    height: 7vh;
    width: 42%;
    padding-left: 2%;
}
#formdiv{
    margin-top: 2%;
    width: 100%;
}
.sele option{
    padding-left: 18%;
}
.git{
position: relative;
margin-left: 10%;
width: 35%;

}

.cimg{
    height: 30px;
    width: 30px;
}

.map{
    margin-top: 8%;
    width: 100%;
    height: 50vh;
}
.contact-sec-2{
    width: auto;
    display: flex;
}
.formdate{
    outline: none;
    height: 7vh;
    width: 44%;
    margin-left: 1.25rem;
    border: 1px solid rgb(180, 180, 180);
    padding-left: 2%;
}
.error1{
    font-size: 1rem;
    margin-left: 12%;
}
.error2{
    font-size: 1rem;
    margin-left: 12%;
}
.rightinput{
    padding-left: 1.25rem;
}
.error12{
    font-size: 1rem;
    margin-left: 12%;
}
/*------------------------------------------------------media queries------------------------------------------------*/

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    .contact-sec-2{
        width: auto;
        display: flex;
        flex-direction: column
    }
.form{
    width: 85%;
    height: auto;
    display: flex;
    flex-direction: column;
}
.formdiv-sec1{
    display: flex;
    flex-direction: column;
    margin-left: 5%;
}
.formdiv-sec3{
    display: flex;
    flex-direction: column;
}
.formdiv-sec2{
    display: flex;
    flex-direction: column;
    margin-left: 5%;
    margin-top: -3.5vh;
}
.forminput{
    margin-left: 5%;
    width: 220%;
}
#options{
    width: 88%;
    margin-left: 7%;
}
.git{
    margin-top: 10%;
    width: 85%;
}
.rightinput{
padding-left: 0;
width: 42%;}

.error1{
    font-size: 0.75rem;
    margin-left: 7%;
}
.error2{
    font-size: 0.75rem;
    margin-left: 7%;
}
.error12{
    font-size: 0.60rem;
    margin-left: 7%;
}
.formdate{
    outline: none;
    height: 7vh;
    width: 88%;
    border: 1px solid rgb(180, 180, 180);
    margin-left: 7%;
    margin-top: 1%;
}
.comment{
    border: 1px solid lightgray;
    height: 15vh;
    width: 88%;
    margin-top: 3%;
   margin-left: 7%;
   margin-right: 10%;
   padding-left: 5%;
    padding-top: 2.5%;
}


}
  

