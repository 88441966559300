.top-bar {
  display: flex;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  justify-content: center;
}

.first-icons {
  display: flex;
  margin-left: 4rem;
  margin-right: auto;
}

.ficon {
  display: flex;
  margin-right: 1rem;

  align-items: center;
}

.ficon > img {
  max-width: 1rem;
  height: auto;
}

.ficon > p {
  font-size: 0.85rem;
  font-weight: 600;
}

.last-icons {
  display: flex;
  margin-left: auto;
  margin-right: 3rem;
  align-items: center;
}

.top-img{
  margin-right: 1rem;
  max-width: 100%;
  height: auto;
}

@media (max-width: 568px) {
  .top-bar {
    display: flex;
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
    align-items: center;
  }

  .first-icons {
    display: flex;
  }

  .ficon > img {
    max-width: 100%;
    height: auto;
    margin-right: 3rem;
  }

  .ficon > p {
    display: none;
  }

  .last-icons {
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    flex-wrap: nowrap;
  }

  .top-img {
    margin-right: 0.8rem;
    max-width: 100%;
    height: auto;
  }
}
